<template>
  <div class="atropos" ref="atroposRef">
    <div class="atropos-scale">
      <div class="atropos-rotate">
        <div class="atropos-inner">
          <div class="relative-position">
            <image-component
              class="dimmed"
              :media="{ image: { asset: { ...props.data.poster.asset } } }"
            />
            <div class="absolute-full flex flex-center">
              <div
                class="q-ma-lg text-center text-subtitle2 text-weight-bold text-white"
                data-atropos-offset="5"
                style="line-height: 1"
              >
                {{ data.reference.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, nextTick, onMounted, ref } from "vue";
import ImageComponent from "src/components/image/ImageComponent.vue";

defineOptions({ name: "ProjectComponent" });

const props = defineProps({
  data: { type: Object, default: () => {} },
  index: { type: Number, default: 0 },
});

const atropos = inject("atropos");
const atroposRef = ref(null);

onMounted(async () => {
  await nextTick();
  const myAtropos = atropos({
    el: atroposRef.value,
    rotateTouch: false,
  });
});
</script>
