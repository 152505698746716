<template>
  <div class="items-center justify-center row">
    <div class="col-12 items-center justify-center row">
      <body-component v-if="data.body" :data="data" />
    </div>
    <div
      :class="contentClass"
      class="full-width q-pr-lg items-center justify-center q-col-gutter-lg q-mt-lg row"
      style="max-width: 80%"
    >
      <div
        v-for="(project, index) in data.projects"
        :class="data.cols"
        class="cursor-pointer"
        :key="project._key"
        ref="projectRefs"
        v-intersection="createIntersectionHandler(index)"
        @click="onClick(index, project)"
      >
        <project-component :data="project" :index="index" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from "vue";
import { useQuasar } from "quasar";
import { useMotion } from "@vueuse/motion";
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";
import BodyComponent from "src/components/body/BodyComponent.vue";
import DialogProjects from "src/components/dialog/DialogProjects.vue";
import ProjectComponent from "./ProjectComponent.vue";

defineOptions({ name: "ProjectsComponent" });

const props = defineProps({
  data: { type: Object, default: () => {} },
});

const $q = useQuasar();
const appStore = useAppStore();
const { contentClass } = storeToRefs(appStore);

const projectRefs = ref([]);
const motionInstances = ref([]);

const createIntersectionHandler = (index) => ({
  handler(entry) {
    const percent = (entry.intersectionRatio * 100).toFixed(0);
    if (motionInstances.value[index]) {
      if (percent > 75) {
        nextTick(() => {
          motionInstances.value[index].apply("enter");
        });
      }
    }
  },
  cfg: {
    threshold: Array.from({ length: 101 }, (_, i) => i / 100),
  },
});

const onClick = (index, project) => {
  $q.dialog({
    component: DialogProjects,
    componentProps: {
      data: project,
      index,
      projects: props.data.projects,
    },
  });
};

onMounted(async () => {
  await nextTick();
  projectRefs.value.forEach((el, index) => {
    motionInstances.value[index] = useMotion(el, {
      initial: {
        opacity: 0,
        y: 10,
      },
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          type: "spring",
          stiffness: 80,
          damping: 10,
          duration: 150,
          mass: 1,
          delay: index * 10,
        },
      },
    });
  });
});
</script>
