<template>
  <div v-intersection="options" :class="contentClass" ref="motionRef">
    <portable-text :components="bodyComponent" :value="data.body" />
  </div>
</template>

<script setup>
import { h, computed, onMounted, nextTick, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { QIcon, useQuasar } from "quasar";
import {
  sharpOpenInNew,
  sharpOpenInBrowser,
} from "@quasar/extras/material-icons-sharp";
import { PortableText } from "@portabletext/vue";
import { useMotion } from "@vueuse/motion";
import { useAppStore } from "src/stores/app";

defineOptions({ name: "BodyComponent" });

const props = defineProps({
  data: { type: Object, default: () => {} },
});

const $q = useQuasar();
const appStore = useAppStore();
const { contentClass } = storeToRefs(appStore);
const h4Class = computed(() => ($q.screen.lt.md ? "text-body1" : "text-h6"));
const linkClasses = "custom-link q-pt-none";
const motionRef = ref(null);
let motionInstance = null;
const percent = ref(0);
const thresholds = [];
for (let i = 0; i <= 1.0; i += 0.01) {
  thresholds.push(i);
}
const options = {
  handler(entry) {
    const val = (entry.intersectionRatio * 100).toFixed(0);
    if (percent.value !== val) {
      percent.value = val;
    }
  },
  cfg: {
    threshold: thresholds,
  },
};

const applyAnimations = () => {
  motionInstance = createMotionInstance();
  motionInstance.apply("initial");
  nextTick(() => {
    motionInstance.apply("enter");
  });
};

const createMotionInstance = () => {
  return useMotion(motionRef, {
    initial: {
      y: 10,
    },
    enter: {
      y: 0,
      transition: {
        type: "spring",
        stiffness: 80,
        damping: 20,
        duration: 250,
        mass: 1,
      },
    },
  });
};

const handleClick = (value) => {
  console.log("handleClick", value);
};

const bodyComponent = {
  marks: {
    link: ({ value }, { slots }) => {
      const target = value?.url?.startsWith("http") ? "_blank" : undefined;
      if (value.banner?.length) {
        return h(
          "span",
          {
            class: linkClasses,
            onClick: () => {
              handleClick(value);
            },
          },
          [
            slots.default?.(),
            h(QIcon, {
              name: sharpOpenInBrowser,
              size: "16px",
            }),
          ]
        );
      } else if (value.url) {
        return h(
          "a",
          {
            href: value.url,
            target,
            class: linkClasses,
            rel: target === "_blank" ? "noopener" : undefined,
          },
          [slots.default?.(), h(QIcon, { name: sharpOpenInNew, size: "16px" })]
        );
      }
    },
  },
  block: {
    h4: (_, { slots }) => {
      return h("h4", { class: h4Class.value }, slots.default?.());
    },
  },
};

onMounted(async () => {
  await nextTick();
  motionInstance = createMotionInstance();
});

watch(percent, (newPercent) => {
  if (newPercent > 75) {
    nextTick(() => {
      applyAnimations();
    });
  } else {
    nextTick(() => {
      motionInstance.apply("initial");
    });
  }
});
</script>
